import revive_payload_client_zzZj50tLJW from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@8.57.1_ioredis@5.4.1_magicast_rtexahn7tzxmo3obrpttn4vjti/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_fGDN3lAnWP from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@8.57.1_ioredis@5.4.1_magicast_rtexahn7tzxmo3obrpttn4vjti/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_853GDalqAq from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@8.57.1_ioredis@5.4.1_magicast_rtexahn7tzxmo3obrpttn4vjti/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_IbYY0iGdIc from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@4.28.0_vite@5.4.11_@types+node@22.10.1_terser@5_cs6t4vedpr44vml254pcw67c5m/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_kkIFyQCnTn from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@8.57.1_ioredis@5.4.1_magicast_rtexahn7tzxmo3obrpttn4vjti/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_qVkZKOqtql from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@8.57.1_ioredis@5.4.1_magicast_rtexahn7tzxmo3obrpttn4vjti/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_NgpU6vGnX6 from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@8.57.1_ioredis@5.4.1_magicast_rtexahn7tzxmo3obrpttn4vjti/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_bbBtgnUnVJ from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@8.57.1_ioredis@5.4.1_magicast_rtexahn7tzxmo3obrpttn4vjti/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/bezoek/.nuxt/components.plugin.mjs";
import prefetch_client_RrOwFgtwXM from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@8.57.1_ioredis@5.4.1_magicast_rtexahn7tzxmo3obrpttn4vjti/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_jXnIJXwPLQ from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.25.0_magicast@0.3.5_rollup@4.28.0/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import switch_locale_path_ssr_HJQnUhYYlh from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.28.0_vue@3.5.13_typescript@5.7.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_5Dk454CHw9 from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.28.0_vue@3.5.13_typescript@5.7.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import formkitPlugin_pZqjah0RUG from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/bezoek/.nuxt/formkitPlugin.mjs";
import plugin_Gh2IFXECgF from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-module-apollo@1.1.2_graphql-ws@5.16.0_graphql@16.9.0__react-dom@18.3.1_react@_vq4qirhqtzxgqglwy36wtzikiy/node_modules/@bttr-devs/nuxt-module-apollo/dist/runtime/plugin.js";
import plugin_mcWejwLp5w from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/node_modules/.pnpm/@nuxt+icon@1.9.1_magicast@0.3.5_vite@5.4.11_@types+node@22.10.1_terser@5.37.0__vue@3.5.13_typescript@5.7.2_/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import errors_nus9NLHohf from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.1.0_@bugsnag+core@7.25.0_@unhead+vue@1.11.13_vue@3.5.13_typescri_ajizwq5ipm2jgeo7fi4l6hmfqe/node_modules/@bttr-devs/nuxt-layer-base/plugins/errors.ts";
import i18n_yY2PVfvaGf from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.1.0_@bugsnag+core@7.25.0_@unhead+vue@1.11.13_vue@3.5.13_typescri_ajizwq5ipm2jgeo7fi4l6hmfqe/node_modules/@bttr-devs/nuxt-layer-base/plugins/i18n.ts";
import apollo_3xWTsiPG8O from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.4.2_@bugsnag+core@7.25.0_@unhead+vue@1.11.13_vue@3.5.13_typescr_6koewsnppszdcuba3e3fnklyna/node_modules/@bttr-devs/nuxt-layer-sofie/plugins/apollo.ts";
import sofie_required_version_egMSaEq2Uy from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.4.2_@bugsnag+core@7.25.0_@unhead+vue@1.11.13_vue@3.5.13_typescr_6koewsnppszdcuba3e3fnklyna/node_modules/@bttr-devs/nuxt-layer-sofie/plugins/sofie-required-version.ts";
export default [
  revive_payload_client_zzZj50tLJW,
  unhead_fGDN3lAnWP,
  router_853GDalqAq,
  _0_siteConfig_IbYY0iGdIc,
  payload_client_kkIFyQCnTn,
  navigation_repaint_client_qVkZKOqtql,
  check_outdated_build_client_NgpU6vGnX6,
  chunk_reload_client_bbBtgnUnVJ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_RrOwFgtwXM,
  plugin_jXnIJXwPLQ,
  switch_locale_path_ssr_HJQnUhYYlh,
  i18n_5Dk454CHw9,
  formkitPlugin_pZqjah0RUG,
  plugin_Gh2IFXECgF,
  plugin_mcWejwLp5w,
  errors_nus9NLHohf,
  i18n_yY2PVfvaGf,
  apollo_3xWTsiPG8O,
  sofie_required_version_egMSaEq2Uy
]